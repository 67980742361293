import React from 'react';
import './App.css';
import RegistrationForm from "./component/registration-form";
import {HashRouter, Route} from 'react-router-dom';
import Footer from "./component/Footer";
import Information from "./component/Information";
import ThankYou from "./component/thank-you";

interface globalState {
	coming: boolean|undefined
}

export const globalState: globalState = {
	coming: undefined
};

export class App extends React.Component<any, any> {
	render() {
		return <HashRouter>
			<Route path="/registration">
				<RegistrationForm/>
			</Route>
			<Route path="/thank-you">
				<ThankYou/>
			</Route>
			<Route path="/" exact={true}>
				<Information/>
			</Route>
			<Footer/>
		</HashRouter>;
	}
}
