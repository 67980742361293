import React from "react";

export default function Footer() {
    return (
        <React.Fragment>
            <a className="photographer"
               href="https://unsplash.com/@blnk_kanvas?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
               target="_blank" rel="noopener noreferrer"
               title="Download free do whatever you want high-resolution photos from Will O">
                <span>
                        <svg xmlns="http://www.w3.org/2000/svg" style={{
                            height: '12px',
                            width: 'auto',
                            position: 'relative',
                            verticalAlign: 'middle',
                            top: '-2px',
                            fill: 'white'
                        }} viewBox="0 0 32 32">
                            <title>unsplash-logo</title>
                        <path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"/>
                    </svg>
                </span>
                <span>Will O</span>
            </a>
        </React.Fragment>
    );
}
