import React from "react";
import {globalState} from "../App";
import {NavigationButton} from "./registration-form";

export default class ThankYou extends React.Component<any, any> {
	render() {
		return <div className="thankYou">
			{globalState.coming && <p>Vielen Dank für deine Anmeldung! </p>}
			{!globalState.coming && <p>Vielen Dank für deine Abmeldung! </p>}
			<NavigationButton className={'btn-primary'} text={'Zurück'} path={'/'}/>
		</div>
	}
}