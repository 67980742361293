// import {Link} from 'react-router-dom';
import React from 'react';
import CarouselComponent from './Carousel';

export default function Information() {
    return (
        <div>
            <div className='row justify-content-center'>
                <h1>Nicola & Regula</h1>
            </div>
            <div className='row justify-content-center'>
                <CarouselComponent/>
            </div>
            <div className='row justify-content-center'>
                <p>Am 20. Oktober um 15:00 haben wir im kleinsten Rahmen im Stadthaus Zürich geheiratet. <br/>
                    Das Fest findet am 19. März ab 16:00 im GZ Buchegg (Raum Pavillon) statt. <br/>
                    Wir freuen uns darauf mit euch anzustossen!
                </p>
                {/*<Link to="/registration" className="btn-primary btn">Zur Anmeldung</Link>*/}
            </div>
        </div>
    );
}
