import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

export default function CarouselComponent() {
    function importAll(r:any) {
        return r.keys().map(r);
    }

    const images = importAll(require.context('../img/wedding-pics/', false, /\.(png|jpe?g|svg)$/));

    return (
        <Carousel interval={0}>
            {images.map((img:string, index:number) => {
                return (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block"
                            src={img}
                            alt="First slide"
                        />
                    </Carousel.Item>
                );
            })}
        </Carousel>
    );
}
