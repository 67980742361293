import {Alert, Button, Col, Form} from "react-bootstrap";
import React, {ReactNode} from "react";
import {useHistory} from 'react-router-dom';
import {globalState} from "../App";

declare global {
    interface Window {
        grecaptcha: ReCaptchaInstance
        captchaOnLoad: () => void
    }
}

interface ReCaptchaExecuteOptions {
    action: string
}

interface ReCaptchaRenderOptions {
    sitekey: string
    size: 'invisible'
}

interface ReCaptchaInstance {
    ready: (cb: () => any) => void
    execute: (key: string, options: ReCaptchaExecuteOptions) => Promise<string>
    render: (id: string, options: ReCaptchaRenderOptions) => any
}

interface FormWithSubmitActionProps {
    formData: any;
    validationCallback: (errors: validationErrors) => void;
    alertCallback: (alert: boolean) => void;
    children?: ReactNode
}

function FormWithSubmitAction(props: FormWithSubmitActionProps) {
    const history = useHistory();

    function onSubmit(e: any) {
        e.preventDefault();
        // todo: show spinner?
        globalState.coming = props.formData.willAttend;
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6LfyvsAUAAAAAHLpRX2-C9NWX7sj7jZMFPGYLX55', {action: 'homepage'}).then((token: string) => {

                fetch('/api/register.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        token: token,
                        env: process.env.NODE_ENV,
                        firstName: props.formData.firstName,
                        lastName: props.formData.lastName,
                        willAttend: props.formData.willAttend,
                        personCount: props.formData.personCount,
                        allergy: props.formData.allergy,
                        remarks: props.formData.remarks,
                        email: props.formData.email,
                    })

                }).then(response => {
                    if (response.status !== 200) {
                        props.alertCallback(true);
                    }
                    response.json().then(data => {
                        if (data.status === 'success') {
                            setTimeout(() => {history.push('/thank-you')});
                        } else {
                            if (data.reason === 'recaptcha') {
                                props.alertCallback(true);
                            } else if (data.reason === 'validation') {
                                props.validationCallback(data.data);
                            }
                        }
                    }).catch(reason => {
                        props.alertCallback(true);
                    })
                });

            });
        });
    }

    return <Form onSubmit={onSubmit}>
        {props.children}
    </Form>
}

interface validationErrors {
    [index: string]: any;
    firstName?: string | undefined;
    lastName?: string | undefined;
    willAttend?: string | undefined;
    personCount?: string | undefined;
    allergy?: string | undefined;
    remarks?: string | undefined;
    email?: string | undefined;
}

interface RegistrationFormState {
    [index: string]: any;

    formData: {
        [index: string]: any;
        firstName: string;
        lastName: string;
        willAttend?: boolean | undefined;
        personCount: number;
        allergy: string;
        remarks: string;
        email: string;
    }
    validationErrors: validationErrors,
    alert: boolean,
    response?: {
        message: string,
        className: string,
    }
}

interface NavigationButtonProps {
    path: string;
    text: string;
    className: string,
}

export function NavigationButton(props: NavigationButtonProps) {
    const history = useHistory();
    function onClick() {
        history.push(props.path);
    }
    return <button className={'btn ' + props.className} onClick={onClick}>{props.text}</button>
}

export default class RegistrationForm extends React.Component<any, RegistrationFormState> {
    constructor(props: any) {
        super(props);
        this.state = {
            formData: {
                firstName: '',
                lastName: '',
                willAttend: undefined,
                personCount: 1,
                allergy: '',
                remarks: '',
                email: '',
            },
            alert: false,
            validationErrors: {
            }
        };
    }

    validationErrors(errors: validationErrors) {
        this.setState({validationErrors: errors})
    }

    responseCallback(message: string, className: string) {
        this.setState({response: {message: message, className: className}});
    }

    setInputState(property: string, value: any) {
        const state = {
            ...this.state,
            formData: {...this.state.formData},
            validationErrors: {...this.state.validationErrors}
        };
        state.formData[property] = value;
        state.validationErrors[property] = undefined;
        this.setState(state);
    }

    render() {
        const {validationErrors, formData} = this.state;
        const attendanceSelected = formData.willAttend !== undefined;
        return <FormWithSubmitAction
            formData={formData}
            validationCallback={(errors: validationErrors) => this.validationErrors(errors)}
            alertCallback={(alert => this.setState({alert: alert}))}
        >
            {this.state.alert && <Alert variant={'warning'}>Es ist ein Fehler aufgetreten, bitte versuche es erneut oder kontaktiere uns direkt.</Alert>}
            <Form.Row>
                <Form.Group as={Col} controlId="formFirstName">
                    <Form.Label>Vorname</Form.Label>
                    <Form.Control value={formData.firstName} type="text" placeholder="Vorname"
                                  className={validationErrors.firstName ? 'is-invalid' : undefined}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setInputState('firstName', e.target.value)}/>
                    {validationErrors.firstName && <div className="invalid-feedback">{validationErrors.firstName}</div>}
                </Form.Group>
                <Form.Group as={Col} controlId='formLastName'>
                    <Form.Label>Nachname</Form.Label>
                    <Form.Control value={formData.lastName} type="text" placeholder="Nachname"
                                  className={validationErrors.lastName ? 'is-invalid' : undefined}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setInputState('lastName', e.target.value)}/>
                    {validationErrors.lastName && <div className="invalid-feedback">{validationErrors.lastName}</div>}
                </Form.Group>
            </Form.Row>
            <Form.Group>
                <button type='button' onClick={() => this.setInputState('willAttend', true)}
                        className={'btn ' + (formData.willAttend === true ? 'btn-primary' : 'btn-secondary')}>Ich nehme
                    Teil
                </button>
                &nbsp;
                <button type='button' onClick={() => this.setInputState('willAttend', false)}
                        className={'btn ' + (formData.willAttend === false ? 'btn-primary' : 'btn-secondary')}>Ich nehme
                    nicht Teil
                </button>
                {validationErrors.willAttend && <div className="invalid-feedback">{validationErrors.willAttend}</div>}
            </Form.Group>
            {formData.willAttend && <Form.Row>
                <Form.Group as={Col} controlId='formPersonCount'>
                    <Form.Label>Anzahl Personen </Form.Label>
                    <Form.Control value={formData.personCount.toString()} type="number" min="1" max="9"
                                  placeholder="Anzahl Personen"
                                  className={validationErrors.personCount ? 'is-invalid' : undefined}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setInputState('personCount', parseInt(e.target.value))}/>
                    {validationErrors.personCount &&
                    <div className="invalid-feedback">{validationErrors.personCount}</div>}
                </Form.Group>
                <Form.Group as={Col} controlId='formAllergy'>
                    <Form.Label>Allergien/Diät </Form.Label>
                    <Form.Control value={formData.allergy} type="text" placeholder="Allergien/Diät"
                                  className={validationErrors.allergy ? 'is-invalid' : undefined}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setInputState('allergy', e.target.value)}/>
                    {validationErrors.allergy && <div className="invalid-feedback">{validationErrors.allergy}</div>}
                </Form.Group>
            </Form.Row>}
            {attendanceSelected && <Form.Group controlId="formRemarks">
                <Form.Label>Bemerkungen</Form.Label>
                <Form.Control as="textarea" rows="3" value={formData.remarks}
                              className={validationErrors.remarks ? 'is-invalid' : undefined}
                              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.setInputState('remarks', e.target.value)}/>
                {validationErrors.remarks && <div className="invalid-feedback">{validationErrors.remarks}</div>}
            </Form.Group>}
            {formData.willAttend && <Form.Group controlId="formNewsletter">
                <Form.Label>Haltet mich auf dem Laufenden!</Form.Label>
                <Form.Control value={formData.email} type="email" placeholder="email@example.com"
                              className={validationErrors.email ? 'is-invalid' : undefined}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setInputState('email', e.target.value)}/>
                {validationErrors.email && <div className="invalid-feedback">{validationErrors.email}</div>}
            </Form.Group>}
            <NavigationButton className={'btn-secondary'} text={'Zurück'} path={'/'}/>
            &nbsp;
            {attendanceSelected && <Button type='submit'>{formData.willAttend ? 'Anmelden' : 'Abmelden'}</Button>}
        </FormWithSubmitAction>
    }
}
